module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-1c9b19a84c/4/buildhome/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"sjkimball-portfolio","short_name":"Sam Kimball","start_url":"/","background_color":"#fbfcfe","theme_color":"#191c1e","display":"standalone","icon":"src/images/portfolio_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2058f94a98e05e07dfb7eef0494eaa96"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-2dfbfa739b/4/buildhome/.yarn/berry/cache/gatsby-plugin-offline-npm-6.13.1-f6177c3827-10c0.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-5a58346401/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
